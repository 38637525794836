import React, { useEffect, useState } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);

  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const checkUser = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken;
        if (token && user && isMounted && !redirected) {
          setRedirected(true);
          console.log('Navigating to /chat with user:', user);
          navigate('/chat', { state: { user } });
        }
      } catch {
        // User not authenticated, stay on landing page
      }
    };

    checkUser();
    return () => {
      isMounted = false;
    };
  }, [navigate, redirected, user]);

  useEffect(() => {
    if (route === 'authenticated' && user) {
      navigate('/chat', { state: { user } });
    }
  }, [route, navigate, user]);

  const text = 'Welcome to Sovatt';

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Navbar Section */}
      <nav className="w-full flex justify-between items-center p-6 bg-white shadow-md">
        <div className="text-2xl font-bold md:text-3xl">Sovatt</div>
        <button
          onClick={() => setShowAuthenticator(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 md:px-6 md:py-3"
        >
          Login
        </button>
      </nav>

      {/* Main Section */}
      <div className="flex flex-col items-center justify-center flex-grow px-4 text-center">
        <h1 className="text-4xl font-extrabold leading-tight sm:text-5xl md:text-6xl">
          {text.split('').map((letter, index) => (
            <span
              key={index}
              className="fade-slide-up"
              style={{
                animationDelay: `${index * 0.1}s`,
              }}
            >
              {letter === ' ' ? '\u00A0' : letter}
            </span>
          ))}
        </h1>

        <p className="mt-6 text-lg text-gray-600 max-w-xl sm:text-xl md:max-w-2xl md:text-2xl">
          Sovatt is your intelligent chat platform. Engage, communicate, and collaborate with AI-driven insights.
        </p>
      </div>

      {/* Authenticator Section */}
      {showAuthenticator && (
        <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
          <Authenticator />
        </div>
      )}

      {/* Footer Section */}
      <footer className="text-center p-6 text-gray-500 text-sm sm:text-base">
        © 2025 Sovatt. All rights reserved.
      </footer>
    </div>
  );
};

export default LandingPage;
