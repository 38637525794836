import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import {
  FiMenu,
  FiMoreVertical,
  FiSearch,
  FiThumbsUp,
  FiThumbsDown,
} from 'react-icons/fi';
import { get, post } from '@aws-amplify/api';
import { Amplify } from 'aws-amplify';
import { uploadData } from '@aws-amplify/storage';
import config from '../amplifyconfiguration.json';

Amplify.configure(config);

const ChatPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state || {};

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [botTyping, setBotTyping] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);

  const [dislikeOptionsOpen, setDislikeOptionsOpen] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [feedbackState, setFeedbackState] = useState({});

  const messagesEndRef = useRef(null);
  const menuRef = useRef(null);

  const reasons = [
    { id: 'inaccurate', label: 'Inaccurate' },
    { id: 'unhelpful', label: 'Unhelpful' },
    { id: 'incomplete', label: 'Incomplete' },
  ];

  const apiName = 'sovattapi'; // Must match your API in aws-exports
  const path = '/chat';

  // Fetch Messages from DynamoDB
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await get({ apiName, path: `${path}` });
        if (response && response.message) {
          setMessages(response.message);
          console.log(response.message);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
    fetchMessages();
  }, []);

  // Auto-scroll to bottom on new messages or changes to botTyping
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, botTyping]);

  // Close the dislike options if user clicks outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dislikeOptionsOpen !== null &&
        !event.target.closest('.dislike-options')
      ) {
        closeDislikeOptions();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dislikeOptionsOpen]);

  // Close the menu if user clicks outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  // Send message to backend
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    // 1. Add the user’s message immediately to state
    const newUserMessage = {
      sender: user?.username || 'Guest',
      content: input,
      timestamp: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, newUserMessage]);
    setInput(''); // Clear input field

    try {
      // 2. Post to your API
      const payload = {
        body: {
          sender: user?.username || 'Guest',
          content: input,
          timestamp: new Date().toISOString(),
        },
      };
      const response = await post({ apiName, path: '/chat/messages', options: payload });
      console.log('Raw API Response:', response);

      // 3. Extract the final message from your API’s response
      const resolvedResponse = await response.response;
      const bodyText = await resolvedResponse.body.text();
      const parsedResponse = JSON.parse(bodyText);

      // For example: { response: JSON.stringify({ sender: "Sovatt Bot", content: "...", ... }) }
      const finalResponse = parsedResponse.response
        ? JSON.parse(parsedResponse.response)
        : null;

      console.log('Final Parsed Response:', finalResponse);

      if (finalResponse && finalResponse.message) {
        // The finalResponse.message should have at least { content: "...", sender: "Sovatt Bot" }
        // We'll simulate typing for finalResponse.message.
        simulateBotResponse(finalResponse.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Simulate the bot “typing” effect
  const simulateBotResponse = (botMessage) => {
    if (!botMessage || !botMessage.content) return;
  
    // If your API returns double-escaped \n, unescape them:
    // const realNewlines = botMessage.content.replace(/\\n/g, '\n');
  
    const botFullContent = botMessage.content;
    let currentIndex = 0;
  
    setBotTyping('');
    setIsBotTyping(true);
  
    const typingInterval = setInterval(() => {
      if (currentIndex < botFullContent.length) {
        setBotTyping((prev) => prev + botFullContent[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setIsBotTyping(false);
  
        // Make sure we store final message with sender "Sovatt Bot" and isFinal = true
        setMessages((prev) => [
          ...prev,
          {
            sender: 'Sovatt Bot',  // or botMessage.sender if it matches your check
            content: botFullContent, // or realNewlines if you unescaped
            timestamp: new Date().toISOString(),
            isFinal: true,
          },
        ]);
      }
    }, 40);
  };
  

  // Sign Out
  async function handleSignOut() {
    try {
      await signOut();
      navigate('/landing');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  }

  // Feedback handling
  const handleLike = (index) => {
    setFeedbackState((prev) => ({
      ...prev,
      [index]: prev[index] === 'like' ? null : 'like',
    }));
    setDislikeOptionsOpen(null);
  };

  const openDislikeOptions = (index) => {
    setFeedbackState((prev) => ({
      ...prev,
      [index]: prev[index] === 'dislike' ? null : 'dislike',
    }));
    setDislikeOptionsOpen(index);
    setSelectedReasons([]);
  };

  const closeDislikeOptions = () => {
    setDislikeOptionsOpen(null);
  };

  const toggleReason = (reason) => {
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((item) => item !== reason)
        : [...prev, reason]
    );
  };

  const submitFeedback = async (index) => {
    const dislikedMessage = messages[index];
    const feedbackData = {
      userId: user?.username || 'Guest',
      chatId: 'chat123', // or whatever suits your logic
      timestamp: new Date().toISOString(),
      // Optionally record entire conversation or subset
      unprocessedMessages: messages.slice(index),
      reasons: selectedReasons,
    };

    try {
      await uploadData({
        path: `feedback/${user?.username || 'Guest'}/${
          feedbackData.chatId
        }_${Date.now()}.json`,
        data: JSON.stringify(feedbackData),
        contentType: 'application/json',
      });
      console.log('Feedback submitted:', feedbackData);
      closeDislikeOptions();
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  // Format time as HH:MM
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="flex h-screen bg-gray-100 relative">
      {/* Sidebar */}
      <div
        className={`w-64 bg-gray-900 text-white fixed lg:relative h-full z-40 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition-transform ease-in-out duration-300`}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold">Chats</h2>
          <FiSearch className="text-xl cursor-pointer" />
        </div>
        <div className="p-4 space-y-4">
          <p className="p-3 bg-gray-800 rounded-lg cursor-pointer">Chat 1</p>
          <p className="p-3 bg-gray-800 rounded-lg cursor-pointer">Chat 2</p>
        </div>
      </div>

      {/* Overlay for mobile sidebar */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      {/* Main Chat Area */}
      <div className="flex flex-col flex-1">
        {/* Header */}
        <header className="flex justify-between items-center p-4 bg-white shadow-md">
          <FiMenu
            className="text-2xl cursor-pointer lg:hidden"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
          <h2 className="text-xl font-semibold">
            {user?.username || 'Guest'}
          </h2>
          <div className="relative" ref={menuRef}>
            <FiMoreVertical
              className="text-2xl cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            />
            {menuOpen && (
              <div className="absolute right-0 bg-white shadow-lg rounded-md mt-2 w-48 z-50">
                <p
                  onClick={handleSignOut}
                  className="p-4 cursor-pointer hover:bg-gray-100"
                >
                  Sign Out
                </p>
                <p className="p-4 text-gray-400">Credits (Coming Soon)</p>
              </div>
            )}
          </div>
        </header>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {/* Render each message */}
          {messages.map((msg, index) => {
            const isBot = msg.sender === 'Sovatt Bot'; 
            return (
              <div
                key={index}
                className={`flex flex-col ${
                  isBot ? 'items-start' : 'items-end'
                }`}
              >
                {/* Bubble */}
                <div
                  className={`p-3 rounded-lg max-w-lg ${
                    isBot
                      ? 'bg-gray-100 text-gray-800'
                      : 'bg-gray-900 text-white'
                  }`}
                  style={{
                    // Preserve line breaks
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                  }}
                >
                  {msg.content}
                  <div
                    className={`text-xs mt-1 ${
                      isBot ? 'text-gray-600' : 'text-gray-200'
                    }`}
                    style={{ textAlign: 'right' }}
                  >
                    {msg.timestamp ? formatTime(msg.timestamp) : ''}
                  </div>
                </div>

                {/* Feedback (only for final bot messages) */}
                {isBot && msg.isFinal && (
                  <div className="flex items-center space-x-4 mt-2">
                    <FiThumbsUp
                      className={`text-xl cursor-pointer ${
                        feedbackState[index] === 'like'
                          ? 'text-green-500'
                          : 'text-gray-400'
                      }`}
                      onClick={() => handleLike(index)}
                    />
                    <FiThumbsDown
                      className={`text-xl cursor-pointer ${
                        feedbackState[index] === 'dislike'
                          ? 'text-red-500'
                          : 'text-gray-400'
                      }`}
                      onClick={() => openDislikeOptions(index)}
                    />
                  </div>
                )}

                {/* Dislike Options */}
                {dislikeOptionsOpen === index && (
                  <div className="dislike-options p-4 bg-white rounded-lg shadow-lg mt-2 border border-gray-200 transition-all transform animate-fadeIn w-80">
                    <h3 className="text-lg font-bold text-center text-gray-800 mb-4">
                      Why was this unhelpful?
                    </h3>
                    <ul className="space-y-3">
                      {reasons.map((reason) => (
                        <li
                          key={reason.id}
                          className={`p-3 flex items-center rounded-md cursor-pointer transition-all border ${
                            selectedReasons.includes(reason.id)
                              ? 'bg-gray-300 border-gray-500 text-gray-800'
                              : 'bg-gray-100 hover:bg-gray-200 text-gray-700 border-gray-300'
                          }`}
                          onClick={() => toggleReason(reason.id)}
                        >
                          <div
                            className={`w-5 h-5 border-2 flex items-center justify-center mr-3 rounded ${
                              selectedReasons.includes(reason.id)
                                ? 'bg-gray-600 border-gray-600'
                                : 'border-gray-400'
                            }`}
                          >
                            {selectedReasons.includes(reason.id) && (
                              <span className="text-white font-bold">✓</span>
                            )}
                          </div>
                          {reason.label}
                        </li>
                      ))}
                    </ul>
                    <div className="flex justify-center mt-5">
                      <button
                        className={`px-5 py-2 rounded-lg text-white ${
                          selectedReasons.length
                            ? 'bg-gray-700 hover:bg-gray-800 transition'
                            : 'bg-gray-400 cursor-not-allowed'
                        }`}
                        onClick={() => submitFeedback(index)}
                        disabled={!selectedReasons.length}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          {/* Bot is typing bubble */}
          {isBotTyping && (
            <div className="flex justify-start">
              <div
                className="p-3 rounded-lg bg-gray-100 text-gray-800 text-sm max-w-lg"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {botTyping}
              </div>
            </div>
          )}

          {/* Always scroll to bottom */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input field */}
        <form onSubmit={sendMessage} className="p-4 bg-white border-t flex">
          <input
            type="text"
            className="flex-1 p-2 border rounded-lg focus:outline-none text-sm"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button
            type="submit"
            className="ml-4 px-4 py-2 bg-black text-white rounded-lg text-sm"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatPage;
