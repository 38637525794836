import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ChatPage from './components/ChatPage';
import { Authenticator } from '@aws-amplify/ui-react';
import ProtectedRoute from './components/ProtectedRoute'

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Redirect from '/' to '/landing' */}
        <Route path="/" element={<Navigate to="/landing" replace />} />
        
        {/* Landing Page with Authenticator.Provider */}
        <Route
          path="/landing"
          element={
            <Authenticator.Provider>
              <LandingPage />
            </Authenticator.Provider>
          }
        />

        {/* Chat Page with Authenticator.Provider */}
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <ChatPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
