// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { tokens } = await fetchAuthSession();
        setIsAuthenticated(!!tokens);  // Set to true if tokens exist
      } catch {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  // While checking auth, prevent flashing
  if (isAuthenticated === null) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  // Redirect to landing if not authenticated
  return isAuthenticated ? children : <Navigate to="/landing" replace />;
};

export default ProtectedRoute;
